export const environment = {
  "version": "2.7.0-dev-90ff4b9",
  "revision": "90ff4b9",
  "production": false,
  "beta": false,
  "canary": true,
  "apiKey": "4b87476d58b04954b39bb536f2bb3d4d",
  "clientId": "47095",
  "client_secret": "jyxH-yr75Xn9hgqHV2SeCsbTFsNRULPhq4vJlLk1z7w",
  "offlineMode": false,
  "highlight_project_id": "2",
  "featureFlags": {
    "enableModslotLimitation": true,
    "enableZeroWaste": true,
    "enableGuardianGamesFeatures": false
  }
};